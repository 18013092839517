import { cva, type VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef } from "react";
import type { IconType } from "react-icons";
import { CgSpinner } from "react-icons/cg";

import { cn } from "~/utils/classnames";

const spinnerStyles = cva("animate-spin-slow", {
  variants: {
    size: {
      default: "h-6 w-6",
      sm: "h-4 w-4",
      lg: "h-8 w-8",
      none: "",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

type SpinnerProps = VariantProps<typeof spinnerStyles> & ComponentPropsWithoutRef<IconType>;

export const Spinner = ({ className, size }: SpinnerProps) => {
  return <CgSpinner className={cn(spinnerStyles({ size, className }))} />;
};
